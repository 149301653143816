<template>
    <div>
        <canvas id="myChart" :width="width" :height="height"></canvas>
    </div>
</template>

<script>
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

export default {
    name: "Chart",
    props: {
        width: {
            type: String,
            default: "100%",
        },
        height: {
            type: String,
            default: "100%",
        },
        type: {
            type: String,
            default: "line",
        },
        data: {
            type: Object,
            default: () => {},
        },
    },
    mounted() {
        const ctx = document.getElementById("myChart").getContext("2d");
        new Chart(ctx, {
            type: this.type,
            data: {
                labels: [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sept",
                    "Oct",
                    "Nov",
                    "Dec",
                ],
                datasets: [
                    {
                        label: "Job Seekers",
                        data: [2, 19, 53, 21, 2, 3, 34, 78, 12, 2, 54, 1],
                        tension: 0.4,
                        backgroundColor: [
                            "rgba(255, 99, 132, 0.2)",
                            "rgba(54, 162, 235, 0.2)",
                            "rgba(255, 206, 86, 0.2)",
                            "rgba(75, 192, 192, 0.2)",
                            "rgba(153, 102, 255, 0.2)",
                            "rgba(255, 159, 64, 0.2)",
                        ],
                        borderColor: "#4D77FF",
                        borderWidth: 2,
                    },
                    {
                        label: "Recruiters",
                        data: [12, 55, 6, 43, 5, 60, 19, 42, 17, 33, 17, 55],
                        tension: 0.4,
                        backgroundColor: [
                            "rgba(255, 99, 132, 0.2)",
                            "rgba(54, 162, 235, 0.2)",
                            "rgba(255, 206, 86, 0.2)",
                            "rgba(75, 192, 192, 0.2)",
                            "rgba(153, 102, 255, 0.2)",
                            "rgba(255, 159, 64, 0.2)",
                        ],
                        borderColor: "#1D1D1D",
                        borderWidth: 2,
                    },
                ],
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true,
                        grid: {
                            color: "#F3F4F6",
                        },
                        ticks: {
                            color: "#9CA3AF",
                            font: {
                                size: 12,
                                family: "Inter",
                            },
                        },
                    },
                    x: {
                        grid: {
                            color: "#F3F4F6",
                        },
                        ticks: {
                            color: "#9CA3AF",
                            font: {
                                size: 12,
                                family: "Inter",
                            },
                        },
                    },
                },

                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                    },
                },
                interaction: {
                    intersect: false,
                    mode: "index",
                },
            },
            plugins: [
                {
                    afterDraw: (chart) => {
                        if (chart.tooltip?._active?.length) {
                            let x = chart.tooltip._active[0].element.x;
                            let yAxis = chart.scales.y;
                            let ctx = chart.ctx;
                            ctx.save();
                            ctx.beginPath();
                            ctx.moveTo(x, yAxis.top);
                            ctx.lineTo(x, yAxis.bottom);
                            ctx.lineWidth = 1;
                            ctx.strokeStyle = "#4D77FF";
                            ctx.stroke();
                            ctx.restore();
                        }
                    },
                },
            ],
            ...this.data,
        });
    },
};
</script>

<style scoped></style>
