<template>
    <div>
        <p class="fs-18 fw-500 mb-4 use-poppins">Dashboard</p>
        <div class="grid grid__layout col-gap-2 row-gap-5">
            <div class="col-4">
                <InfoCard type="raised secondary" style="height: 100%">
                    <div class="flex flex__column flex__spaced flex__row-gap-2">
                        <p class="text__light-blue fs-18 fw-400">Total Users</p>
                        <p class="fs-40 text-black fw-400">0</p>
                    </div>
                    <div class="info blue flex flex__item-center flex__center">
                        <img
                            src="@/assets/icons/total-users-icon.svg"
                            alt="total-users-icon"
                        />
                    </div>
                </InfoCard>
            </div>
            <div class="col-4">
                <InfoCard type="raised secondary" style="height: 100%">
                    <div class="flex flex__column flex__spaced flex__row-gap-2">
                        <p class="text-purple fs-18 fw-400">Job Seekers</p>
                        <p class="fs-40 text-black fw-400">0</p>
                    </div>
                    <div
                        class="info purple flex flex__item-center flex__center"
                    >
                        <img
                            src="@/assets/icons/people-icon-white.svg"
                            alt="people-icon-white"
                        />
                    </div>
                </InfoCard>
            </div>
            <div class="col-4">
                <InfoCard type="raised secondary" style="height: 100%">
                    <div class="flex flex__column flex__spaced flex__row-gap-2">
                        <p
                            class="text-purple fs-18 fw-400"
                            style="color: #1d1d1d"
                        >
                            Recruiters
                        </p>
                        <p class="fs-40 text-black fw-400">0</p>
                    </div>
                    <div class="info black flex flex__item-center flex__center">
                        <img
                            src="@/assets/icons/recruiters-icon-white.svg"
                            alt="recruiters-icon-white"
                        />
                    </div>
                </InfoCard>
            </div>
            <div class="col-6">
                <InfoCard type="raised secondary" style="height: 100%">
                    <div class="flex flex__spaced">
                        <div
                            class="flex flex__column flex__spaced flex__row-gap-2"
                        >
                            <p
                                class="text__dark-grey fs-18 fw-400"
                                style="color: #1d1d1d"
                            >
                                Total Jobs
                            </p>
                            <p class="fs-50 text-black fw-400">0</p>
                        </div>
                        <div
                            class="flex flex__column flex__spaced flex__row-gap-2"
                        >
                            <div class="flex flex__align-end flex__column">
                                <p class="text__dark-grey fs-13 fw-400">
                                    Active jobs
                                </p>
                                <p class="text__dark-grey fs-13">0</p>
                            </div>
                            <div class="flex flex__align-end flex__column">
                                <p class="text-red fs-13 fw-400">
                                    Inactive jobs
                                </p>
                                <p class="text__dark-grey fs-13">0</p>
                            </div>
                        </div>
                    </div>
                </InfoCard>
            </div>
            <div class="col-6">
                <InfoCard type="raised secondary" style="height: 100%">
                    <div class="flex flex__spaced">
                        <div
                            class="flex flex__column flex__spaced flex__row-gap-2"
                        >
                            <p
                                class="text__dark-grey fs-18 fw-600"
                                style="color: #1d1d1d"
                            >
                                Available Jobs
                            </p>
                            <p class="fs-50 text-black fw-400">0</p>
                        </div>
                        <div
                            class="flex flex__column flex__spaced flex__row-gap-2"
                        >
                            <div class="flex flex__align-end flex__column">
                                <p class="text__dark-grey fs-13 fw-400">
                                    Posted by Recruiter
                                </p>
                                <p class="text__dark-grey fs-13">0</p>
                            </div>
                            <div class="flex flex__align-end flex__column">
                                <p class="text__light-blue fs-13 fw-400">
                                    Posted by Admin
                                </p>
                                <p class="text__dark-grey fs-13">0</p>
                            </div>
                        </div>
                    </div>
                </InfoCard>
            </div>
            <div class="col-8">
                <InfoCard
                    type="raised secondary"
                    class="flex flex__column flex__row-gap-3"
                    style="height: 100%"
                >
                    <p class="fs-20 fw-600 text__dark-grey">Analytics Report</p>
                    <div class="flex__grow flex flex__column flex__spaced">
                        <Chart width="100%" height="330" class="mb-3" />
                        <div class="flex flex__center flex__col-gap-2">
                            <div class="flex flex__item-center">
                                <span
                                    class="b-rad--10-p w-1 h-1 d-block"
                                    style="background: #4d77ff"
                                />
                                &nbsp;
                                <span
                                    class="text__light-black fs-14 fw-400"
                                    style="font-family: Montserrat"
                                >
                                    Job seekers
                                </span>
                            </div>
                            <div class="flex flex__item-center">
                                <span
                                    class="b-rad--10-p w-1 h-1 d-block"
                                    style="background: #1d1d1d"
                                />
                                &nbsp;
                                <span
                                    class="text__light-black fs-14 fw-400"
                                    style="font-family: Montserrat"
                                >
                                    Recruiters
                                </span>
                            </div>
                        </div>
                    </div>
                </InfoCard>
            </div>
            <div class="col-4">
                <InfoCard type="raised secondary" style="height: 100%">
                    <p class="fs-16 fw-600 text__dark-grey mb-2">
                        Recently Registered
                    </p>

                    <div>
                        <div
                            v-for="(seeker, i) in newSeekers"
                            :key="i"
                            class="flex flex__item-center mb-2"
                            :class="{ 'mb-4': newSeekers.length === i + 1 }"
                        >
                            <div class="mr-1 w-4 flex">
                                <img
                                    style="width: 100%"
                                    src="../../../../assets/icons/avatar-icon.svg"
                                    alt="avatar-icon"
                                />
                            </div>
                            <div>
                                <p class="fs-12 fw-400">{{ seeker.name }}</p>
                                <p class="fs-12 fw-500">{{ seeker.email }}</p>
                            </div>
                        </div>
                    </div>
                    <p class="text-center">
                        <router-link
                            :to="{ name: 'adminJobSeekers' }"
                            class="text__light-blue fs-12 fw-600 cursor-pointer"
                        >
                            View all
                        </router-link>
                    </p>
                </InfoCard>
            </div>
        </div>
    </div>
</template>

<script>
import InfoCard from "@/components/InfoCard";
import Chart from "@/components/Chart";

export default {
    name: "AdminOverview",
    components: {
        InfoCard,
        Chart,
    },
    data() {
        return {
            newSeekers: [
                {
                    name: "Jerome Bell",
                    email: "debra.holt@example.com",
                },
                {
                    name: "Courtney Henry",
                    email: "tim.jennings@example.com",
                },
                {
                    name: "Savannah Nguyen",
                    email: "michelle.rivera@example.com",
                },
                {
                    name: "Annette Black",
                    email: "tanya.hill@example.com",
                },
                {
                    name: "Annette Black",
                    email: "tanya.hill@example.com",
                },
                {
                    name: "Jerome Bell",
                    email: "debra.holt@example.com",
                },
                {
                    name: "Courtney Henry",
                    email: "tim.jennings@example.com",
                },
            ],
        };
    },
};
</script>

<style scoped lang="scss">
.info {
    width: 59px;
    height: 63px;
    border-radius: 5px;
    position: absolute;
    top: 0;
    transform: translateY(-50%);
    right: 40px;

    &.blue {
        background: #1aa7ec;
    }
    &.purple {
        background: #4d77ff;
    }
    &.black {
        background: #1d1d1d;
    }
}
</style>
